import { authEndpoints } from './auth'
import { orderEndpoints } from './order'
import { driverEndpoints } from './driver'
import { vehicleEndpoints } from '@/shared/endpoints/vehicles'
import { darkstoreEndpoints } from '@/shared/endpoints/darkstores'
import { userEndpoints } from '@/shared/endpoints/user'
import { workShiftEndpoints } from '@/shared/endpoints/work-shifts'
import { invitationEndpoints } from '@/shared/endpoints/invitation'
import { reportEndpoints } from '@/shared/endpoints/report'
import { manualChangesEndpoints } from '@/shared/endpoints/manual-changes'
import { zoneEndpoints } from '@/shared/endpoints/zones'
import { notificationEndpoints } from '@/shared/endpoints/notifications'
import { predefinedReasons } from '@/shared/endpoints/predefined-reasons'
import { cacheSettlementsEndpoints } from '@/shared/endpoints/cache-settlements'
import { settingsEndpoints } from '@/shared/endpoints/settings'
import { generalEndpoints } from '@/shared/endpoints/general'
import { cityEndpoints } from '@/shared/endpoints/city'
import { erpNextEndpoints } from '@/shared/endpoints/erp-next'

const config = window?._runtimeConfig ?? {}

export const apiBase = config.VUE_APP_API_HOST + config.VUE_APP_API_BASE
export const locationsApiBase = config.VUE_APP_API_LOCATION_HOST + config.VUE_APP_API_BASE

export const apiEndpoints = {
  ...authEndpoints,
  order: { ...orderEndpoints },
  driver: { ...driverEndpoints },
  vehicle: { ...vehicleEndpoints },
  darkstores: { ...darkstoreEndpoints },
  users: { ...userEndpoints },
  workShifts: { ...workShiftEndpoints },
  invitation: { ...invitationEndpoints },
  report: { ...reportEndpoints },
  manualChanges: { ...manualChangesEndpoints },
  zones: { ...zoneEndpoints },
  notifications: { ...notificationEndpoints },
  predefinedReasons,
  cacheSettlements: { ...cacheSettlementsEndpoints },
  settings: { ...settingsEndpoints },
  general: { ...generalEndpoints },
  cities: { ...cityEndpoints },
  erpNext: { ...erpNextEndpoints }
}
