import { Method as apiMethod } from 'axios'

export const orderEndpoints = {
  list: {
    method: 'get' as apiMethod,
    url: '/order/page'
  },
  listAutocomplete: {
    method: 'get' as apiMethod,
    url: '/order/search_autocomplete'
  },
  returnedItems: {
    method: 'get' as apiMethod,
    url: '/returned_order_items/page'
  },
  byId: {
    method: 'get' as apiMethod,
    url: '/order/'
  },
  returnedItembyId: {
    method: 'get' as apiMethod,
    url: '/returned_order_items/'
  },
  cacheSettlement: {
    method: 'get' as apiMethod,
    url: '/cache_settlement/order_cache_settlement/page'
  },
  acceptedOrder: {
    method: 'get' as apiMethod,
    url: '/order/driver_orders/count'
  },
  assignOrder: {
    method: 'post' as apiMethod,
    url: '/order/manual_single_order_assignment/'
  },
  unassignOrder: {
    method: 'post' as apiMethod,
    url: '/order/unassign_order/'
  },
  manuelOrderAssign: (orderId: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/order/manual_order_assignment/${orderId}`
    }
  },
  manuelOrderUnAssign: (orderId: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/order/manual_order_unassignment/${orderId}`
    }
  },
  assignMultipleOrder: {
    method: 'post' as apiMethod,
    url: '/order/manual_multiple_order_assignment/'
  },
  cancelOrder: {
    method: 'put' as apiMethod,
    url: '/order/cancel_order/'
  },
  statuses: {
    method: 'get' as apiMethod,
    url: '/order/order_statuses/all'
  },
  updateStatus: {
    method: 'put' as apiMethod,
    url: '/order/update_order_status_internally/'
  },
  updateVehicleType: {
    method: 'put' as apiMethod,
    url: '/order/update_order_vehicle_type/'
  },
  nearByOrders: {
    method: 'get' as apiMethod,
    url: '/order/nearby_orders/'
  },
  orderLastStatus: {
    method: 'get' as apiMethod,
    url: '/order/order_latest_statuses/'
  },
  toggleManual: (orderId: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/order/toggle_manual/${orderId}`
    }
  },
  reTryErpSync: (orderId: number) => {
    return {
      method: 'post' as apiMethod,
      url: `/order/${orderId}/retry_sync`
    }
  },
  createGeneralOrder: {
    method: 'post' as apiMethod,
    url: '/order/create_general_order'
  },
  importGeneralOrder: (darkstoreId: number, erpNextMerchant: string) => {
    return {
      method: 'post' as apiMethod,
      url: `/order/import_general_order/${darkstoreId}/${erpNextMerchant}`
    }
  }
}
