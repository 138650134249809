
import { defineComponent } from 'vue'
import { changeLocale } from '@/i18n'
import { useToast } from 'vue-toastification'
import axiosInstance from '@/shared/utils/axiosInstance'
const config = window?._runtimeConfig ?? {}

export default defineComponent({
  name: 'App',
  beforeMount () {
    const mapScript = document.createElement('script')
    mapScript.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?libraries=places,drawing,geometry&key=' + config.VUE_APP_MAP_KEY)
    document.head.appendChild(mapScript)
    const locale = String(localStorage.getItem('language'))
    if (localStorage.getItem('language')) changeLocale(locale)

    axiosInstance.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error && error.response) {
        const toast = useToast()
        if (error.response.status === 400 || error.response.status === 409) {
          toast.error(error.response.data?.info?.message, {
            timeout: 15000
          })
        } else if (error.response.status === 403) {
          toast.error('You don`t have permission!', {
            timeout: 15000
          })
        } else if (error.response.status === 405) {
          toast.error(error.response.data?.detail, {
            timeout: 15000
          })
        } else if (error.response.status === 500) {
          toast.error('Something went wrong!', {
            timeout: 15000
          })
        }
      }
      return Promise.reject(error)
    })
  }
})
