import { CommonGetResponseInterface } from '@/shared/interfaces/commoon-get-response.interface'
import { CommonModel } from '@/shared/models/common.model'
import { OrdersSettlementInterface } from '@/shared/interfaces/reports/orders-settlement.interface'

export class OrdersSettlementModel extends CommonModel {
  public data: OrdersSettlementInterface[] = [];

  constructor (data: CommonGetResponseInterface) {
    super(data)
    this.modifyData()
    this.clearBaseData()
  }

  private modifyData (): void {
    this.baseData.forEach((item: any) => {
      this.data = [...this.data, { ...this.setData(item) }]
    })
  }

  private setData (data: any): OrdersSettlementInterface {
    return {
      adminUsername: data.admin_username,
      canceledErpSyncStatus: data.canceled_erp_sync_status,
      deliveredErpSyncStatus: data.delivered_erp_sync_status,
      pickupErpSyncStatus: data.pickup_erp_sync_status,
      driverId: data.driver_id,
      driverUsername: data.driver_username,
      orderId: data.order_id,
      orderStatus: data.order_status,
      orderStatusId: data.order_status_id,
      orderTotal: data.order_total,
      createdAt: data.created_at,
      settlementId: data.settlement_id,
      settlementNo: data.settlement_no,
      settlementStatus: data.settlement_status,
      id: data.id
    }
  }
}
