import { RouteRecordRaw } from 'vue-router'
import profileRoutes from '@/views/account/drivers/router'
import LTSDashboardRoutes from '@/views/account/lts-dashboard/router'

const routesAccount: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/orders/all'
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: () => import('./drivers/Drivers.vue')
  }, {
    path: '/reports/:reportName',
    name: 'Reports',
    component: () => import('./reports/Reports.vue')
  }, {
    path: '/dark-stores',
    name: 'DarkStores',
    component: () => import('./darkstores/DarkstoresList.vue')
  }, {
    path: '/drivers/locations',
    name: 'DriversLocations',
    component: () => import('./drivers/DriversLocations.vue')
  }, {
    path: '/orders/:orderType',
    name: 'Orders',
    meta: {
      set: 'all'
    },
    component: () => import('./orders/Orders.vue')
  }, {
    path: '/cache-settlements/:type',
    name: 'CacheSettlements',
    meta: {
      set: 'all'
    },
    component: () => import('./cache-settlements/CacheSettlements.vue')
  },
  {
    path: '/cache-settlements/:type/:id',
    name: 'CacheSettlementDetail',
    component: () => import('./cache-settlements/CacheSettlementsDetail.vue')
  },
  {
    path: '/returned-items',
    name: 'ReturnedItems',
    component: () => import('./returnedItems/ReturnedItems.vue')
  }, {
    path: '/profile/:id',
    name: 'Profile',
    redirect: { name: 'CurrentStatus' },
    component: () => import('./drivers/Profile.vue'),
    children: profileRoutes
  }, {
    path: '/vehicles',
    name: 'Vehicles',
    component: () => import('./vehicles/Vehicles.vue')
  }, {
    path: '/shifts/list',
    name: 'WorkShifts',
    component: () => import('./workshifts/WorkShifts.vue')
  }, {
    path: '/shifts/requests',
    name: 'OvertimeRequests',
    component: () => import('./workshifts/OvertimeRequests.vue')
  }, {
    path: '/users',
    name: 'Users',
    component: () => import('./users/Users.vue')
  }, {
    path: '/manual-changes',
    name: 'ManualChanges',
    component: () => import('./manual-changes/ManualChanges.vue')
  },
  {
    path: '/zones',
    name: 'Zones',
    component: () => import('./zones/Zones.vue')
  },
  {
    path: '/location-tracking-service',
    name: 'LTSDashboard',
    component: () => import('./lts-dashboard/LTSDashboard.vue'),
    children: LTSDashboardRoutes
  },
  {
    path: '/zones/add-new',
    name: 'NewZone',
    component: () => import('./zones/NewZone.vue')
  },
  {
    path: '/zones/edit/:zoneId',
    name: 'EditZone',
    component: () => import('./zones/EditZone.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('./settings/Settings.vue')
  }
]

export default routesAccount
